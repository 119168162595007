// :root{
//     --primary:#548CFF;
//     --secondary:#121212;
//     --text-color:#121212;
//     --border-color:#DEDEDE;
//     --body-color:#FFF;
//     --card-bg:#F9F9F9;
//     --para:#989CA5;
// }
.light{
    --primary:#3468E5;
    --secondary:#121212;
    --text-color:#121212;
    --border-color:#DEDEDE;
    --body-color:#FFF;
    --card-bg:#F9F9F9;
    --para:#989CA5;
    --tab-bg:#E5E5E8;
    --card-body:#F9F9F9;
    --card-input:#fff;
    --chat-btn:#11192B;
    --chat-btn-txt:#fff;
    --card-airline:#fff;
    --radio-btn:#DEDEDE;
    --radio-btn-border:#a5a5a5;
}
.dark{
    --primary:#3468E5;
    --secondary:#121212;
    --text-color:#fff;
    --border-color:#3B3B3B;
    --body-color:#121212;
    --card-bg:#212121;
    --para:#989CA5;  
    --tab-bg:#212121;
    --card-body:transparent;
    --card-input:#212121;
    --chat-btn:#fff;
    --chat-btn-txt:#11192B;
    --card-airline:#383838;
    --radio-btn:#3A3A3A;
    --radio-btn-border:#a5a5a5;
}