@import "../../../assets/scss/variable.scss";
@import "../../../assets/scss/font.scss";

.dashboard {
  max-width: 68rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__card {
    height: calc(100vh - 19rem);
    background-color: var(--card-body);
    border: 0.1rem solid var(--border-color);
    overflow: auto;
    position: relative;
  }

  // 1
  &__text-area {
    position: fixed;
    bottom: 2.4rem;
    width: 73%;
    right: 0;
    left: 0;
    width: 68rem;
    margin: 0 auto;
  }

  &__input-box-search {
    min-height: 5rem !important;
    width: 100%;
    word-break: break-all;
    margin-top: 0.8rem;
    border: 0.1rem solid var(--border-color);
    background-color: var(--card-bg);
    color: var(--text-color);
    // padding-top: 1.3rem !important;
    // padding-right: 7rem !important;

    &:focus {
      border: 0.1rem solid var(--border-color);
    }

    &:hover {
      border: 0.1rem solid var(--border-color);
    }

  }

  &__img-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__img-inner {
    height: 50rem;
    width: 50rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__upload-btn {
    background-color: var(--primary);
    color: #fff !important;
    font-family: $font-Medium;
    font-size: 1.6rem;
    height: 4rem;
    padding: 0 3.5rem;
    border: none;
    outline: none;
    box-shadow: none;

    &:hover {
      color: #fff !important;
    }
  }

  &__fetch-btn {
    background-color: var(--primary);
    color: #fff;
    font-family: $font-Medium;
    font-size: 1.6rem;
    height: 4rem;
    text-align: center;
    width: 19rem;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &__tab {
    background-color: var(--tab-bg);
    padding: 0.4rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    position: fixed;
    top: 1rem;
    z-index: 9999;
    left: 35.5%;
  }

  &__tab-box {
    background-color: var(--tab-bg);
    padding: 0.4rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.4rem;
  }

  &__tab-active {
    background-color: var(--body-color);
    display: flex;
    align-items: center;
    gap: 0.8rem;
    height: 4rem;
    padding: 0 2rem;
    border-radius: 5rem;
    cursor: pointer;

    p {
      color: var(--text-color);
      font-size: 1.4rem;
      font-family: $font-Regular;
      margin: 0;
    }

    img {
      fill: brightness(10%);
    }
  }

  &__tab-default {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    height: 4rem;
    padding: 0 2rem;
    border-radius: 0.8rem;
    cursor: pointer;

    p {
      color: var(--para);
      font-size: 1.4rem;
      font-family: $font-Regular;
      margin: 0;
    }
  }

  &__file-upload {
    background-color: var(--card-input);
    padding: 2.4rem;
    border-radius: 0.8rem;
    border: 0.1rem solid var(--border-color);
    width: 90%;
    margin-top: 4rem;
    height: 32rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__progress {
    position: absolute;
    bottom: -1.4rem;
    left: 0;
    right: 0;
  }

  &__text-area-inner {
    background-color: var(--card-input) !important;
    padding: 2.4rem;
    border: 0.1rem solid var(--border-color);
    width: 90%;
    margin-top: 4rem;
    height: 25rem !important;
  }

  &__input-box-site {
    background-color: var(--card-input) !important;
    padding: 2.4rem;
    border: 0.1rem solid var(--border-color);
    width: 100%;
    height: 4rem;
  }

  &__input-box-site-box {
    background-color: var(--card-input) !important;
    border: 0.1rem solid var(--border-color);
    width: 100%;
    height: 3rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    color: var(--para);
    font-family: $font-Regular;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
    margin: 3rem 0 2rem;

    p {
      color: var(--text-color);
      margin: 0;
      font-family: $font-Medium;
    }
  }

  &__progress-load {
    margin-top: 2rem;
  }

  &__border {
    border-bottom: 0.1rem solid var(--border-color);
    width: 100%;
  }

  &__lable-txt {
    color: var(--text-color);
    font-size: 1.4rem;
    font-family: $font-Medium;
    text-align: left;
  }

  &__form-item {
    text-align: left;
    display: block;
  }

  &__form-content {
    width: 90%;
    margin-top: 2rem;
  }

  &__input-content {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 2rem;
    margin-top: 3rem;
  }

  &__lable-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  &__input-content-box {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    gap: 2rem;
  }

  &__file-txt {
    font-size: 1.6rem;
    font-family: $font-Regular;
    color: var(--text-color);
    margin: 3rem 0 0;


  }

  &__file-center {
    img {
      margin-bottom: 1rem;
    }

    p {
      color: var(--para);
      font-size: 1.2rem;
      font-family: $font-Regular;
    }

    h6 {
      color: var(--para);
      font-size: 1.4rem;
      font-family: $font-Regular;
    }
  }

  &__send {
    background-color: var(--primary);
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__send-textarea {
    background-color: var(--primary);
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 1.4rem;
    bottom: 0.6rem;

  }

  &__profile {
    background: var(--border-color);
    width: 3.4rem;
    height: 3.4rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 1.4rem;

    h1 {
      margin: 0;
      font-size: 1.6rem;
      font-family: $font-Medium;
      color: var(--text-color);
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
  }

  &__logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: relative;
  }

  &__logo-text {
    img {
      margin-bottom: 1.2rem;
    }
  }

  &__logo-text-second {
    max-width: 60%;
    margin: 0 auto;

    img {
      margin-bottom: 1.2rem;
    }
  }

  &__logo-text-box {
    max-width: 60%;
    margin: 0 auto;

    img {
      margin-bottom: 1.2rem;
    }
  }

  &__message-content {
    background-color: var(--primary);
    max-width: 68rem;
    padding: 0.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-family: $font-Regular;
    padding: 0 3.2rem;
    height: 3.6rem;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    left: 0;
    // transition: all .35s ease;
    transform: translatey(4rem);

    // animation: slideIn 1s forwards;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-family: $font-Bold;
    margin-bottom: 1.2rem;
    text-align: center;
    text-transform: capitalize;
    color: var(--text-color);
  }

  &__para {
    color: var(--para);
    font-size: 1.4rem;
    text-align: center;
    font-family: $font-Regular;
    margin-bottom: 3.2rem;
    line-height: 1.4;
  }

  &__list-content {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      font-size: 1.4rem;
      color: var(--text-color);
      font-family: $font-Medium;
      margin-bottom: 2rem;
      min-width: 71%;

      span {
        text-align: left;
        padding-right: 2rem;

        img {
          margin: 0;
        }
      }
    }
  }

  &__arrow-top {
    background-color: var(--primary);
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    border-radius: 0 0 0.8rem 0.8rem;
    cursor: pointer;
    transition: all 0.6s ease;
    z-index: 100;
  }

  &__arrow-down {
    border: 0.1rem solid var(--border-color);
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: var(--body-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 2rem;
    right: 2rem;
    float: right;
    cursor: pointer;
  }

  &__chatbox {
    display: flex;
    flex-direction: column;
  }

  &__sender {
    width: 100%;
    background-color: var(--body-color);
    border-bottom: 0.1rem solid var(--border-color);
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__sender-container {
    padding: 2.4rem 0 2.4rem 0;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    justify-content: space-between;
  }

  &__sender-name {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  &__chat-btn {
    border-radius: 8px;
    border: none;
    outline: none;
    box-shadow: none;
    background: var(--primary);
    font-size: 16px;
    font-family: $font-Medium;
    color: #fff;
    height: 6rem;
    width: 100%;
  }

  &__sender-text {
    font-size: 1.6rem;
    font-family: $font-Regular;
    line-height: 1.5;
    color: var(--text-color);
    word-wrap: break-word;
    word-break: break-all;

    p {
      margin: 0;
    }
  }

  &__copy {
    cursor: pointer;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__spinner {
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  &__response {
    width: 100%;
    background-color: transparent;
    border-bottom: 0.1rem solid var(--border-color);
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__response-container {
    padding: 2.4rem 0 2.4rem 0rem;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
  }

  &__response-name {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  &__fight-services {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-left: 2rem;
    // margin-top: 20px;
    // align-items: center;
    // justify-content: space-between;
  }

  &__response-text {
    font-size: 1.6rem;
    font-family: $font-Regular;
    line-height: 1.5;
    color: var(--text-color);

    pre {
      white-space: pre-wrap;
      /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;
      /* Mozilla, since 1999 */
      white-space: -pre-wrap;
      /* Opera 4-6 */
      white-space: -o-pre-wrap;
      /* Opera 7 */
      word-wrap: break-word;
      /* Internet Explorer 5.5+ */
      font-size: 1.6rem;
      font-family: $font-Regular;
      line-height: 1.5;
      color: var(--text-color);
      width: 100%;
    }
  }

  &__card-box {
    padding: 1.6rem;
    background-color: var(--card-airline);
    width: 45.2rem;
    border-radius: 1.2rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 33px 24px -17px rgba(0, 0, 0, 0.12), 0px 0px 16px 4px rgba(0, 0, 0, 0.04);

      .arrow-img {
        img {
          filter: brightness(10);
        }

      }
    }
  }

  &__card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    img {
      margin: 0;
    }
  }

  &__card-inner-coming-soon {
    display: flex;
    align-items: center;
    gap: 5px;
    // justify-content: space-between;
    // text-align: center;
    flex-direction: row;
  }

  &__coming-soon-text {
    margin: 0;
    font-size: 1.3rem;
    font-family: $font-Regular;
    color: var(--para);
  }

  &__card-inner-img-second {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    img {
      margin: 0;
    }

    h4 {
      margin: 0;
      font-size: 1.6rem;
      font-family: $font-Medium;
      color: var(--text-color);
    }
  }

  &__card-airline {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__flightcard {
    margin-top: 40px;
    width: 100%;
  }

  &__flightcard-selected {
    margin-top: 40px;
    width: 100%;
  }

  &__flightcard-small {
    width: 100%;
    max-width: 36rem;
  }

  &__flightcard-body {
    background-color: var(--tab-bg);
    border: 0.1rem solid var(--border-color);
    box-sizing: border-box;
    border-radius: 1.6rem;
    width: 100%;
    padding: 2.4rem;
    margin: 0 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__flightcard-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0.5rem 0 1.6rem;
  }

  &__trip-info {
    font-size: 1.6rem;
    color: var(--text-color);
    font-family: $font-SemiBold;
  }

  &__trip-date {
    font-size: 1.6rem;
    color: var(--text-color);
    font-family: $font-Medium;
    text-transform: capitalize;
    position: relative;
    // padding-left: 2rem;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 0.4rem;
      height: 0.4rem;
      top: 0.8rem;
      border-radius: 50%;
      background-color: var(--text-color);
      margin: 0 2rem 0;
      right: 8rem;
    }
  }

  &__triptype {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    align-items: flex-start;
    margin: 0rem 0 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__trip-detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    flex-direction: column;
  }

  &__trip-detail-booking {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    flex-direction: column;
    width: 100%;
  }

  &__trip-timeings {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  &__trip-time {
    color: var(--text-color);
    font-size: 1.4rem;
    font-family: $font-Medium;
  }

  &__trip-hr {
    color: var(--text-color);
    font-size: 1.2rem;
    font-family: $font-Regular;
  }

  &__trip-username {
    color: var(--text-color);
    font-size: 1.4rem;
    font-family: $font-SemiBold;
    margin-bottom: 0;
  }

  &__flightcard-overlay {
    height: 13rem;
    overflow: auto;
    margin-bottom: 2rem;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: 0;
    }
  }

  // &__flightcard-overlay::-webkit-scrollbar {
  //   display: none;
  // }
  &__flight-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__trip-booking {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__trip-booking-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
  }

  &__icon-box {
    display: flex;
    align-items: center;
  }

  &__trip-distance {
    color: var(--primary);
    font-size: 1.4rem;
    font-family: $font-Medium;
  }

  &__trip-view {
    color: var(--primary);
    font-size: 1.4rem;
    font-family: $font-Medium;
    cursor: pointer;
  }

  &__book-btn {
    background-color: var(--primary);
    border: 0.1rem solid var(--primary);
    width: auto;
    padding: 0.8rem 1.4rem;
    border-radius: 2.4rem;
    color: #fff;
    font-family: $font-Medium;
    font-size: 1.4rem;
    cursor: pointer;
  }

  &__change-btn {
    background-color: var(--secondary);
    border: 0.1rem solid var(--secondary);
    width: auto;
    padding: 0.8rem 1.4rem;
    border-radius: 2.4rem;
    color: #fff;
    font-family: $font-Medium;
    font-size: 1.4rem;
    cursor: pointer;
  }

  &__download-btn {
    background-color: var(--primary);
    border: 0.1rem solid var(--primary);
    width: auto;
    padding: 0.8rem 1.4rem;
    border-radius: 2.4rem;
    color: #fff;
    font-family: $font-Medium;
    font-size: 1.4rem;
    cursor: pointer;
    min-width: 145px;
  }

  &__card-row {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: auto;
  }
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

.searchflight {
  width: 100%;
  height: 200px;
  margin-top: 10%;
  // background-color: #fff;
  display: flex;
  flex-direction: column;

  // margin-left:20px;
  &__places-item {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-around;
    // margin-top: 20px;
    margin: 1.5rem;
    height: 4rem;
  }

  &__placeInputs {
    // width:100px;
    width: 500px;
    // background-color: var(--card-body);
    // border: 1px solid var(--border-color);
  }

  &__submit-button {
    background-color: var(--primary);
    width: 100%;
    color: var(--text-color)
  }
}


@media screen and (min-width: 320px) and (max-width: 991px) {
  .searchflight {
    width: 100%;

    // height: 600px;
    &__places-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 4rem;
      gap: 10px;

      // width: 100%;
    }

    &__placeInputs {
      // width:100px;
      width: 315px;
      // margin: 1.5rem;
      // background-color: var(--card-body);
      // border: 1px solid var(--border-color);
    }

    &__passengerInput {
      width: 315px;
    }

    &__submit-button {
      background-color: var(--primary);
      width: 50%;
      color: var(--text-color)
    }
  }

}

@media only screen and (max-width: 1400px) {
  .auth {
    &__bg-txt {
      max-width: 100%;
    }
  }
}

.flex {
  display: flex;
  gap: 2rem;
  // align-items: center;
}

.dynamicAlign {
  display: flex;
  justify-content: center;
  /* Set the alignment horizontally */
  align-items: center;
  /* Set the alignment vertically */
}

.animation-class {
  animation: slideIn 1s forwards;
  position: sticky;
}

.reverse-animation-class {
  animation: slideOut 1s forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@media only screen and (max-width: 576px) {
  .p-4 {
    padding: 0 1.5rem 1.5rem;
  }

  .dashboard {
    &__sender {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &__sender-container {
      padding: 1rem;
      padding-right: 0;
    }

    &__response {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &__response-container {
      padding: 1rem;
      padding-right: 0;
      gap: 1rem;
    }

    &__sender-text {
      font-size: 1.4rem;
    }

    &__response-text {
      font-size: 1.4rem;

      ul,
      ol {
        padding-right: 0;
      }
    }
  }

  .flex {
    gap: 1.5rem;
  }
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .p-4 {
    padding: 2rem;
  }

  .dashboard {
    &__sender {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &__sender-container {
      padding: 1.5rem 0 1.5rem 1.5rem;
    }

    &__response {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &__response-container {
      padding: 1.5rem 0 1.5rem 1.5rem;
    }

    &__response-text {

      ul,
      ol {
        padding-right: 0;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader-demo-box {
  border-radius: 0.25rem !important;
}

.loader-demo-box {
  width: 100%;
  height: 200px;
}

.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--primary);
  margin: 14px 5px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .dashboard {
    &__logo {
      max-width: 100% !important;
    }

    &__tab {
      position: static;
      width: 100%;
      margin: 2rem auto 0;
      display: flex;
      justify-content: center;
      width: 32rem;
      z-index: 0;
    }

    &__img-inner {
      height: 30rem;
      width: 30rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

    // &__card {
    //   height: calc(100vh - 25rem);
    // }
  }

  .dashboard__card-box {
    width: unset;
  }

  .dashboard__card-airline {
    margin: 2rem 1rem;
  }

  // .dashboard__card {
  //   height: unset;
  // }

  // .dashboard__chatbox {
  //   height: calc(100vh - 19rem);
  // }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .dashboard {
    &__logo {
      max-width: 100% !important;
    }

    &__tab {
      position: static;
      width: 100%;
      margin: 2rem auto 0;
      display: flex;
      justify-content: center;
      width: 32rem;
      z-index: 0;
    }

    &__img-inner {
      height: 30rem;
      width: 30rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__card {
      height: calc(100vh - 25rem);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .dashboard {
    &__logo {
      max-width: 100% !important;
    }

    &__tab {
      left: 31%;
    }

    &__img-inner {
      height: 30rem;
      width: 30rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .dashboard {
    &__logo {
      max-width: 100% !important;
    }

    &__tab {
      left: 37%;
    }

    &__img-inner {
      height: 40rem;
      width: 40rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

  }
}


.file {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.file>input[type="file"] {
  display: none;
}

.file>label {
  .profile-img {
    background-color: var(--primary);
    color: #fff;
    font-family: $font-Medium;
    font-size: 1.6rem;
    height: 4rem;
    padding: 0 3.5rem;
    width: 12.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    gap: 0.5rem;

    p {
      color: var(--text);
      font-size: 1.4rem;
      font-family: $font-Medium;
      margin: 0;
    }

  }

  .banner-img {
    border-radius: 0.5rem !important;

  }
}

.file-attach {
  color: var(--para);
  font-size: 1.6rem;
  font-family: $font-Regular;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;

  img {
    cursor: pointer;
  }
}

.attach-file-txt {
  color: var(--text-color);
  font-family: $font-Medium;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.response-text-secont {
  margin-bottom: 2rem;

  h4 {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1.6rem;
    font-family: "Montserrat-Regular";
    line-height: 1.5;
    color: var(--text-color);
    width: 100%;
  }
}

body.light .dashboard__card-box:hover .dashboard__card-inner .arrow-img {
  filter: brightness(0);
}

body.dark .cab-round img {
  filter: brightness(10);
}