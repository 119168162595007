@import "./assets/scss/font.scss";
@import "./assets/scss/variable.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--body-color);
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  top: 7rem;
  padding: 0 4rem 4rem;
}

.cursor {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ant-input {
  background-color: var(--card-bg) !important;
  color: var(--text-color) !important;
  font-family: $font-Regular;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--border-color);
  border-inline-end-width: 1px;
  z-index: 1;
}

.ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.ant-input::placeholder {
  color: #989ca5 !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--border-color);
}

*::-webkit-scrollbar {
  width: 0.8rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 100px;
}

.blink {
  width: 1rem;
  height: 2.5rem;
  background-color: #548cff;
  animation: blink 1s step-end infinite;
  border-radius: 0.2rem;
}

@keyframes blink {

  from,
  to {
    background-color: transparent;
  }

  50% {
    background-color: #548cff;
  }
}

.marquee {
  font-size: 1.4rem;
  height: 2.5rem;
  overflow: hidden;
  background-color: #548cff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}

.marquee a {
  display: block;
  width: 200%;
  overflow: hidden;
  animation: marquee 17s linear infinite;
  position: absolute;
  color: #fff;
  font-family: $font-Regular;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container {
    padding: 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 680px) {
  .container {
    padding: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .marquee a {
    width: 500%;
  }
}

.chat {
  position: fixed;
  top: 90px;
  left: 55px;
}

#login-form {
  .ant-input {
    background-color: #fff !important;
    color: #121212 !important;
    font-family: $font-Regular;
  }
}

.ant-notification-notice-message {
  font-family: $font-Medium !important;
  color: var(--text-color) !important;
}

.ant-notification-notice-description {
  display: none;
}

.ant-notification .ant-notification-notice {
  background: var(--card-bg) !important;
}

.ant-notification-notice-close {
  .ant-notification-notice-close-x {
    .anticon .anticon-close .ant-notification-notice-close-icon {
      color: var(--text-color) !important;
    }
  }
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  color: var(--text-color) !important;
}

textarea:where(.css-dev-only-do-not-override-10ed4xt).ant-input {
  resize: none;
  display: flex;
  align-items: center;
  padding-top: 1.3rem !important;
  padding-right: 7rem !important;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-btn-default:not(:disabled):hover {
  color: #fff;
  border-color: var(--primary);
  box-shadow: none;
}

.ant-progress .ant-progress-bg {
  background-color: var(--primary) !important;
}

.ant-progress .ant-progress-text {
  color: var(--text-color);
  font-family: $font-Medium;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-btn-default:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: #fff;
  background-color: var(--primary);
  box-shadow: none;
  cursor: not-allowed !important;
}

.ant-btn-default:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

#submit-btn {
  .ant-btn-default:disabled {
    cursor: not-allowed;
    border-color: var(--primary);
    color: #fff;
    background-color: var(--primary);
    box-shadow: none;
  }
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper {
  background: var(--tab-bg);
  border: none;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
  background: var(--primary);
  border-color: var(--primary);
  font-family: $font-Regular;

}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.ant-radio-group-large :where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper:first-child {
  border-start-start-radius: 5rem;
  border-end-start-radius: 5rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper:first-child {
  border: none;
}

.ant-radio-group-large :where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper:last-child {
  border-start-end-radius: 5rem;
  border-end-end-radius: 5rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper:hover {
  color: var(--text-color);
}

.ant-radio-group-large :where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper {
  font-size: 16px;
  line-height: 38px;
  border-radius: 5rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}

.ant-radio-button-wrapper {
  color: var(--text-color);
  font-family: $font-Regular;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: transparent;
}

.ant-timeline .ant-timeline-item-head {
  border: none;
  width: 1rem;
  height: 1rem;
  background-color: var(--border-color);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-timeline .ant-timeline-item-tail {
  position: absolute;
  inset-block-start: 1.4rem;
  inset-block-end: 1.4rem;
  inset-inline-start: 0.4rem;
  height: calc(100% - 17px);
  border-inline-start: 2px solid var(--border-color);
}

.timeline-head {
  color: var(--text-color);
  font-size: 1.6rem;
  font-family: $font-Medium;
}

.timeline-cab {
  color: var(--text-color);
  font-size: 1.6rem;
  font-family: $font-Medium;
  margin: 0;
}

.cab-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cab-round {
  background-color: var(--tab-bg);
  border: 0.1rem solid var(--border-color);
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__icon-box {
  .cab-round:nth-child(2) {
    margin-left: -12px;
  }
}

body.dark .cab-round img {
  filter: brightness(10);
}

.cab-content-inner {
  display: flex;
  gap: 2rem;
}

.cap-location {
  color: var(--text-color);
  font-size: 1.2rem;
  font-family: $font-Regular;
  line-height: 2.5rem;

  span {
    color: var(--primary);
  }
}

.cap-confirm {
  margin-top: 1rem;
  width: 20rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-divider {
  border-block-start: 1px solid var(--border-color);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-divider-horizontal {
  margin: 1rem 0 0;
}

.cap-content-check {
  display: flex;
  justify-content: space-between;
  width: 47rem;
  margin: 0 auto;
}

/*radio button issues fix*/

.ant-radio-group-large :where(.css-10ed4xt).ant-radio-button-wrapper:first-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-radius: 24px;
}

:where(.css-10ed4xt).ant-radio-button-wrapper {
  background: var(--radio-btn);
  border: 1px solid var(--radio-btn-border);
  margin-right: 12px;
  border-radius: 24px !important;
}

:where(.css-10ed4xt).ant-radio-button-wrapper:first-child {
  border-inline-start: 1px solid var(--radio-btn-border);
}

.ant-radio-group-large :where(.css-10ed4xt).ant-radio-button-wrapper:last-child {
  border-start-end-radius: 24px;
  border-end-end-radius: 24px;
}

:where(.css-10ed4xt).ant-radio-button-wrapper:not(:first-child)::before {
  visibility: hidden;
}

:where(.css-10ed4xt).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--primary);
  color: #fff;
}

:where(.css-10ed4xt).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}

:where(.css-10ed4xt).ant-radio-button-wrapper:hover {
  position: relative;
  color: var(--text-color);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker {
  background-color: var(--card-bg);
  color: var(--text-color);
  // border: 1px solid var(--border-color);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker .ant-picker-suffix {
  color: var(--para);
}

.ant-picker-large .ant-picker-input>input::placeholder {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-large .ant-picker-input>input {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-header-view {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-content th {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-cell-in-view {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-cell {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-header button {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-picker-dropdown .ant-picker-today-btn {
  color: var(--para);
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--card-bg);
  color: var(--text-color);
  // border: 1px solid var(--border-color) !important;
  font-family: "Montserrat-Regular";
  width: 315px;
  height: 40px;
}

#searchFlight {
  .ant-select-single {
    width: 100% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--card-input);
    border: 1px solid #d9d9d9;
    height: 40px !important;
    color: var(--text-color) !important;
    font-family: "Montserrat-Regular";
    padding: 4px 11px;
    font-size: 14px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--para) !important;
  }
}

#flightDate {
  .ant-picker {
    background: var(--card-input);
  }

  .ant-picker .ant-picker-suffix {
    color: var(--para);
    ;
  }

  .ant-picker-large .ant-picker-input>input {
    color: var(--text-color);
  }
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select .ant-select-selection-placeholder {
  color: var(--para) !important;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 11px;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select-dropdown {
  background-color: var(--card-bg);
  color: var(--para);
  font-family: "Montserrat-Regular";
  border: 1px solid var(--border-color) !important;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-select-dropdown .ant-select-item {
  color: var(--para);
  font-family: "Montserrat-Regular";
}

/*88end commit***/
.fixed-btn {
  position: fixed;
  right: 1rem;
  left: 1rem;
  bottom: 2rem;
}