@import "../../assets/scss/font.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7rem;
    max-width: 1920px;
    margin: 0 auto;
    border-bottom: 1px solid var(--border-color); 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--body-color);
    z-index: 100;
    padding: 0 4rem;
    &__end {
        display: flex;
        align-items: center;
        gap: 3rem;
    }
     &__profile{
        background: var(--border-color);
        width: 4rem;
        height: 4rem;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        h1{
         margin: 0;
         font-size: 2rem;
         font-family: $font-Medium;
         color: var(--text-color);
        }
     }
     &__tab{
        background-color: var(--card-bg);
        padding: 0.4rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.4rem;
    }
    &__tab-active{
        background-color: var(--body-color);
        display: flex;
        align-items: center;
        gap: 0.8rem;
        height: 4rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        p{
          color: var(--text-color);
          font-size: 1.4rem;
          font-family: $font-Regular;
          margin: 0;
        }
    }
    &__tab-default{
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        height: 4rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        p{
          color: var(--para);
          font-size: 1.4rem;
          font-family: $font-Regular;
          margin: 0;
        }
    }
}

.menu{
    display: flex;
    align-items: center;
    gap: 1rem;
    h1{
        font-size: 1.6rem;
        font-family: $font-Medium;
        color: var(--secondary);
        margin: 0;
    }
}