@import "../../assets/scss/variable.scss";
@import "../../assets/scss/font.scss";

.auth {
    height: 100vh;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        background-color: #E9E9EE;

    }

    &__left-inner {
        max-width: 60rem;

        h2 {
            color: #fff;
            font-size: 3.2rem;
            font-family: $font-Bold;
            margin-top: 6.4rem;
            line-height: 3.8rem;
            letter-spacing: -0.128rem;
        }

        p {
            color: #838383;
            font-size: 2.8rem;
            font-family: $font-Regular;
            line-height: 3.5rem;
            letter-spacing: -0.096rem;
        }
    }

    &__conrainer-left {
        background-color: #121212;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__container-card {
        background-color: #fff;
        padding: 4.8rem;
        max-width: 43rem;
        min-width: 43rem;
        border-radius: 0.8rem;

    }

    &__logo {
        margin-bottom: 2rem;
    }

    &__form-title {
        color: #11192B;
        font-size: 1.8rem;
        font-family: $font-Bold;
        text-align: left;
        margin-bottom: 1.8rem;
    }

    &__form-para {
        color: #989CA5;
        font-size: 1.4rem;
        font-family: $font-Medium;
        text-align: left;
        margin-bottom: 3rem;
        line-height: 1.6;
    }

    &__input-box {
        height: 4.8rem;
        width: 100%;
        margin-top: 0.8rem;
        border: 0.1rem solid #E0E0E0;
        background-color: #fff;
        color: #11192B;

        &:focus {
            border: 0.1rem solid #11192B;
        }

        &:hover {
            border: 0.1rem solid #11192B;
        }
    }

    &__label-text {
        color: #11192B;
        font-size: 1.2rem;
        font-family: $font-SemiBold;

    }

    &__form-link {
        color: #989CA5;
        font-family: $font-SemiBold;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        cursor: pointer;
    }

    &__icon {
        cursor: pointer;
    }

    &__auth-btn {
        background-color: #121212;
        border-radius: 0.4rem;
        color: #fff;
        font-family: $font-SemiBold;
        font-size: 1.4rem;
        height: 4.8rem;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: #121212 !important;
        }
    }

    &__text-link {
        font-family: $font-SemiBold;
        color: #121212 !important;
        font-size: 1.4rem;
    }
}

.mt-1 {
    margin-top: 1rem;
}


@media (min-width: 990px) and (max-width: 1100px) {
    .auth__left-inner {
        max-width: 45rem;
    }

    .auth__left-inner h2 {
        font-size: 2.8rem;
    }

    .auth__left-inner p {
        font-size: 2rem;
    }
}

@media (min-width: 1101px) and (max-width: 1380px) {
    .auth__left-inner {
        max-width: 50rem;
    }

    .auth__left-inner h2 {
        font-size: 2.8rem;
    }

    .auth__left-inner p {
        font-size: 2rem;
    }
}


@media (min-width: 320px) and (max-width: 480px) {
    .auth {
        &__container-card {
            background-color: #fff;
            padding: 2.8rem;
            max-width: unset !important;
            min-width: unset !important;
            border-radius: 0.8rem;
            margin: 0 2rem;

        }
    }

}

.upilayout {
    max-width: 115rem;
    height: 100vh;
    margin: 0 auto;

    &__payment {
        padding: 3rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F9F9F9;
        height: 100vh;
        border: 1px solid #DEDEDE;
    }

    &__main-title {
        font-size: 2.4rem;
        font-family: $font-Bold;
        color: var(--text-color);
        margin-bottom: 0.5rem;
    }

    &__sub-title {
        font-size: 1.8rem;
        font-family: $font-Bold;
        color: var(--text-color);
        margin-bottom: 0.5rem;
    }

    &__para {
        font-size: 1.4rem;
        font-family: $font-Medium;
        color: #989CA5;
        margin-bottom: 0.5rem;
    }

    &__sub-para {
        font-size: 1.4rem;
        font-family: $font-Medium;
        color: #989CA5;
        margin-bottom: 0.5rem;
    }

    &__scan-pay {
        margin: 2rem 0;
    }

    &__input-box {
        height: 4.8rem;
        border: 0.1rem solid #000;
        width: 100%;
        border-radius: 0.4rem;
        outline: none;
        box-shadow: none;
        padding: 0.8rem 1.4rem;
        margin-bottom: 2rem;
    }

    &__scanQR {
        width: 12rem;
        height: 12rem;
        object-fit: contain;
    }

    &__verify-btn {
        background-color: #3468E5;
        border: 0.1rem solid #3468E5;
        width: 100%;
        height: 4.8rem;
        padding: 0.8rem 1.4rem;
        border-radius: 2.4rem;
        border: none;
        box-shadow: none;
        color: #fff;
        font-family: $font-Medium;
        font-size: 1.4rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__cancel-btn {
        background-color: #000;
        border: 0.1rem solid #000;
        width: 100%;
        height: 4.8rem;
        padding: 0.8rem 1.4rem;
        border-radius: 2.4rem;
        border: none;
        box-shadow: none;
        color: #fff;
        font-family: $font-Medium;
        font-size: 1.4rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
    }

    &__instruction {
        ul {
            margin-top: 2rem;
            list-style: none;

            li {
                font-size: 1.4rem;
                font-family: $font-Regular;
                color: #989CA5;
                margin: 0.4rem 0;

                &::before {
                    content: "•";
                    color: #989CA5;
                    font-weight: bold;
                    display: inline-block;
                    width: 0.8em;
                    height: 0.8em;
                    font-size: 2.8rem;
                }
            }
        }
    }

    &__booking {
        border-right: 1px solid #DEDEDE;
        height: 100vh;
        width: 100%;
    }

    &__booking-headerlast {
        padding: 2.6rem 2.4rem;

    }

    &__booking-header {
        padding: 2.6rem 2.4rem;
        border-bottom: 1px solid #DEDEDE;

        h6 {
            font-size: 1.8rem;
            margin: 0;
            font-family: $font-Bold;
            color: #000;
        }

        h4 {
            font-size: 1.6rem;
            margin-top: 1.6rem;
            font-family: $font-Bold;
            color: #000;
        }

        h3 {
            font-size: 1.6rem;
            font-family: $font-Bold;
            color: #000;
        }
    }

    &__flight-light {
        display: flex;
        gap: 1rem;
        font-size: 1.2rem;
        color: #000;
        font-family: $font-Regular;
        align-items: center;

    }

    &__flight-from {
        display: flex;
        gap: 1rem;
        font-size: 1.6rem;
        color: #000;
        font-family: $font-Regular;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__justify {
        display: flex;
        justify-content: space-between;
    }

    &__gst {
        font-size: 1.4rem;
        color: #000;
        font-family: $font-Bold;
        line-height: 2.4rem;
    }

    &__price {
        font-size: 1.4rem;
        color: #000;
        font-family: $font-Regular;
    }

    &__divieder {
        position: relative;
        height: 45vh;
        width: 100%;
        color: #9F9F9F;

        .vertical-divider {
            position: absolute;
            display: table;
            text-align: center;
            height: 100%; // CHANGE ME!
            width: 100%;

            .center-element {
                position: relative;
                display: table-cell;
                vertical-align: middle;

                &:before,
                &:after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    left: 50%;
                    border-left: 1px solid #DEDEDE;
                }

                &:before {
                    bottom: 50%;
                    top: 0;
                    margin-bottom: 20px;
                }

                &:after {
                    top: 50%;
                    bottom: 0;
                    margin-top: 20px;
                }
            }
        }
    }
}

.h-100 {
    height: 100%;
}